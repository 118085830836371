
import Auth from '../api/auth/Auth';
import AgentSummary from '../components/agents/Summary.vue';
import GiftCertificateSummary from '../components/gift_certificates/GiftCertificateSummary.vue';
import TeeTimeSummary from '../components/tee_times/TeeTimeSummary.vue';

import Vue from 'vue';

Vue.component('agent-summary', AgentSummary);
Vue.component('gift-cert-summary', GiftCertificateSummary);
Vue.component('tee-time-summary', TeeTimeSummary);

export default Vue.component('home', {
  data() {
    return {
      selectedDate: '',
      updateCounter: 0,
    };
  },
  mounted() {
    this.setSelectedDate();
  },
  computed: {
    isAdmin() {
      return Auth.isAdmin();
    },
  },
  methods: {
    setSelectedDate() {
      const date = new Date();
      this.selectedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      this.refreshSummaries();
    },
    refreshSummaries() {
      this.updateCounter++;
    },
  },
});
