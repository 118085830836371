import { Customer, DefaultCustomer } from '../customers/customer';
import { Course, DefaultCourse } from '../courses/course';
import {
  GiftCertificate,
  DefaultGiftCertificate,
} from '../gift_certificates/gift_certificate';
import { Discount, DefaultDiscount } from '../discounts/discount';
import { Rate } from '../rates/rate';
import { TransactionItem } from '../transaction_items/transaction_item';
import {
  TransactionRecord,
  DefaultTransactionRecord,
} from '../transaction_record/transaction_record';

export interface TeeTime {
  id: number;
  agent: string;
  created_at: string;
  course_name: any; // TODO Replace with iface once ready.
  play_date: string;
  play_time: string;
  gift_certificate_amount: string;
  discount_amount: string;
  confirmation: string;
  notes: string;
  internal_notes: string;
  folio: string;
  players: number;
  customer: Customer;
  course: Course;
  rate?: Rate;
  gift_certificate?: GiftCertificate;
  discount?: Discount;
  transaction_item?: TransactionItem;
  transaction_record?: TransactionRecord;
  should_email_customer?: boolean;
  should_email_course?: boolean;
  cost: number;
  status: string;
  pipedrive_deal_id: number;
  availability_queue_id: number;
}

const DEFAULT_TEE_TIME: TeeTime = {
  id: -1,
  agent: '',
  created_at: '',
  course_name: '',
  play_date: '',
  play_time: '',
  folio: '',
  gift_certificate_amount: '',
  discount_amount: '',
  confirmation: '',
  notes: '',
  internal_notes: '',
  players: 0,
  customer: DefaultCustomer(),
  course: DefaultCourse(),
  gift_certificate: DefaultGiftCertificate(),
  discount: DefaultDiscount(),
  transaction_record: DefaultTransactionRecord(),
  should_email_customer: true,
  should_email_course: true,
  cost: 0,
  status: '',
  pipedrive_deal_id: 0,
  availability_queue_id: 0,
};

export function DefaultTeeTime(): TeeTime {
  return JSON.parse(JSON.stringify(DEFAULT_TEE_TIME));
}
