
import { AgentLeaderboard, AgentSummaries } from '../../api/agents/summary';
import MonthYearPicker from '../dates/MonthYearPicker.vue';

import Vue from 'vue';

export default Vue.component('agent-summary', {
  props: ['isMonthly', 'title', 'date'],
  data() {
    return {
      data: {} as AgentSummaries,
      colors: [
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getColors(i: number) {
      return this.colors[i] ?? 'yellow';
    },
    load() {
      if (!this.date) {
        return;
      }
      AgentLeaderboard.get(this.date).then((response) => {
        this.data = response;
        setTimeout(() => {
          this.load();
        }, 500000); // Five minutes.
      });
    },
  },
});
