var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',[_c('div',[_vm._v("Daily Leaderboard:")]),_c('v-carousel',{attrs:{"height":"auto","hide-delimiters":""}},[_c('v-carousel-item',[(
              this.data.dailyRounds === undefined ||
                this.data.dailyRounds.length == 0
            )?_c('div',{staticClass:"black--text text-center",staticStyle:{"height":"75px"}},[_vm._v(" No daily data available. ")]):_vm._e(),(this.data.dailyRounds !== undefined)?_c('div',_vm._l((this.data.dailyRounds),function(daily,i){return _c('v-progress-linear',{key:daily.name,attrs:{"value":daily.quantizedRounds,"color":_vm.getColors(i),"height":"25"}},[[_c('strong',[_vm._v(_vm._s(daily.name)+" - "+_vm._s(daily.rounds)+" rounds")])]],2)}),1):_vm._e()]),_c('v-carousel-item',[(
              this.data.dailyRevenue === undefined ||
                this.data.dailyRevenue.length == 0
            )?_c('div',{staticClass:"black--text text-center",staticStyle:{"height":"75px"}},[_vm._v(" No daily revenue data available. ")]):_vm._e(),_vm._l((this.data.dailyRevenue),function(daily,i){return _c('v-progress-linear',{key:daily.name,attrs:{"value":daily.quantizedRevenue,"color":_vm.getColors(i),"height":"25"}},[[_c('strong',[_vm._v(_vm._s(daily.name)+" - $"+_vm._s(daily.revenue))])]],2)})],2)],1),_c('br'),_c('div',[_vm._v("Monthly Leaderboard:")]),_c('v-carousel',{attrs:{"height":"auto","hide-delimiters":""}},[_c('v-carousel-item',[(
              this.data.monthlyRounds === undefined ||
                this.data.monthlyRounds.length == 0
            )?_c('div',{staticClass:"black--text text-center",staticStyle:{"height":"75px"}},[_vm._v(" No monthly rounds data available. ")]):_vm._e(),_vm._l((this.data.monthlyRounds),function(monthly,i){return _c('v-progress-linear',{key:monthly.name,attrs:{"value":monthly.quantizedRounds,"color":_vm.getColors(i),"height":"25"}},[[_c('strong',[_vm._v(_vm._s(monthly.name)+" - "+_vm._s(monthly.rounds)+" rounds")])]],2)})],2),_c('v-carousel-item',[(
              this.data.monthlyRevenue === undefined ||
                this.data.monthlyRevenue.length == 0
            )?_c('div',{staticClass:"black--text text-center",staticStyle:{"height":"75px"}},[_vm._v(" No monthly revenue data available. ")]):_vm._e(),_vm._l((this.data.monthlyRevenue),function(monthly,i){return _c('v-progress-linear',{key:monthly.name,attrs:{"value":monthly.quantizedRevenue,"color":_vm.getColors(i),"height":"25"}},[[_c('strong',[_vm._v(_vm._s(monthly.name)+" - $"+_vm._s(monthly.revenue))])]],2)})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }