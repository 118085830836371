
import { AUTH_NAME } from '../api/auth/Auth';
import {
  BookingController,
  BookingData,
  DefaultBookingData,
} from '../api/booking/booking';
import { Course } from '../api/courses/course';
import CourseSelector from '../components/courses/CourseSelector.vue';
import { Deal } from '../api/pipedrive/deal';
import DealIndex from '../components/pipedrive/DealIndex.vue';
import FormAlerts from '../components/alerts/FormAlerts.vue';
import {
  ComputeDiscountAmount,
  DefaultDiscount,
} from '../api/discounts/discount';
import { FormProcessor } from '../api/forms/processor';
import { Rate } from '../api/rates/rate';
import { RentalClub } from '../api/rental_clubs/rental_club';
import {
  RentalClubIndex,
  RentalClubIndexOptions,
} from '../api/rental_clubs/rental_club_index';
import { QueueIbeUtil } from '../api/queue/queue_ibe_util'
import Vue from 'vue';
import { DefaultGiftCertificate } from '../api/gift_certificates/gift_certificate';
import { formatDate, parseDate } from '../api/dates/date_formatter';
import { CourseList } from '../api/courses/list';
import html2canvas from 'html2canvas';

Vue.component('course-selector', CourseSelector);
Vue.component('deal-index', DealIndex);
Vue.component('form-alerts', FormAlerts);

const NO_TEE_TIME_FIELDS = 'Fill out tee time fields first.';

export default Vue.extend({
  data() {
    return {
      data: DefaultBookingData() as BookingData,
      loadDeals: false,
      shouldClearDeals: false,
      processor: new FormProcessor(),
      rate: null as Rate | null,
      userName: localStorage.getItem(AUTH_NAME),
      discountErrors: [] as string[],
      giftCertErrors: [] as string[],
      playDateFormatted: '',
      dealId: null as number | null,
      selectedDealId: 0,
    };
  },
  mounted() {
    this.processor.setParams(
      /* id= */ -1,
      this.data,
      DefaultBookingData(),
      undefined,
      /* saveCallback= */ this.handleSave,
    );
    if (this.$route.query.providedQueueId) {
      this.data.tee_time.availability_queue_id = Number(this.$route.query.providedQueueId);
      this.loadCardFromQueue(this.data.tee_time.availability_queue_id );
    } 
    if (this.$route.query.providedPlayers) {
      this.data.tee_time.players = Number(this.$route.query.providedPlayers);
    }
    if (this.$route.query.providedTimeString) {
      this.data.tee_time.play_time = String(this.$route.query.providedTimeString);
    }
    if (
      this.$route.query.providedPlayers !== undefined &&
      !Number.isNaN(this.$route.query.providedPlayers)
    ) {
      this.data.tee_time.players = Number(this.$route.query.providedPlayers);
    }
    if (this.$route.query.providedDateString) {
      this.data.tee_time.play_date = String(
        this.$route.query.providedDateString,
      );
    }
    if (this.$route.query.providedFirstName) {
      this.data.tee_time.customer.first_name = String(
        this.$route.query.providedFirstName,
      );
    }
    if (this.$route.query.providedLastName) {
      this.data.tee_time.customer.last_name = String(
        this.$route.query.providedLastName,
      );
    }
    if (this.$route.query.providedPostCode) {
      this.data.tee_time.customer.postcode = String(
        this.$route.query.providedPostCode,
      );
    }

    if (this.$route.query.providedEmail) {
      this.data.tee_time.customer.email = String(
        this.$route.query.providedEmail,
      );
    }
  },
  methods: {
    checkPlayers(event: Event) {
      (event.target as HTMLInputElement).select();
    },
    findDeals() {
      this.loadDeals = !this.loadDeals;
    },
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    applyDiscount() {
      window.scrollTo(0, 0);
      this.discountErrors = [];
      if (this.data.discount_code === '') {
        this.data.tee_time.discount = DefaultDiscount();
        return;
      }
      if (!BookingController.teeTimeFieldsValid(this.data)) {
        this.discountErrors.push(NO_TEE_TIME_FIELDS);
      } else {
        this.processor.saveData(
          BookingController,
          this.data,
          this.$refs.submitForm,
          BookingController.applyDiscount,
        );
      }
      this.validateForm();
    },
    validateForm(): boolean {
      return (this.$refs.submitForm as Vue & {
        validate: () => boolean;
      }).validate();
    },
    applyGift() {
      window.scrollTo(0, 0);
      this.giftCertErrors = [];
      if (this.data.gift_cert_serial === '') {
        this.data.tee_time.gift_certificate = DefaultGiftCertificate();
        return;
      }
      if (!BookingController.teeTimeFieldsValid(this.data)) {
        this.giftCertErrors.push(NO_TEE_TIME_FIELDS);
      } else {
        this.processor.saveData(
          BookingController,
          this.data,
          this.$refs.submitForm,
          BookingController.applyGiftCert,
        );
      }
      this.validateForm();
    },
    handleCourseUpdate(courseId: number) {
      this.data.tee_time.course = { id: courseId, course_name: '' } as Course;
      this.maybeLoadRate();
      this.processor.setModified();
    },
    handleSave(bookingData: BookingData) {
      if (this.data.tee_time.confirmation.length > 0) {
        const courseId = this.data.tee_time.course.id;
        this.data = DefaultBookingData();
        this.data.tee_time.course.id = courseId;
        // Trigger a reset of the deals form. The value doesn't matter.
        this.shouldClearDeals = !this.shouldClearDeals;
        return;
      }
      if (bookingData.tee_time.discount !== undefined) {
        this.data.tee_time.discount = bookingData.tee_time.discount;
      }
      if (bookingData.tee_time.gift_certificate !== undefined) {
        this.data.tee_time.gift_certificate =
          bookingData.tee_time.gift_certificate;
      }
    },
    maybeLoadRate() {
      if (
        this.data.tee_time.play_date &&
        this.data.tee_time.play_time &&
        this.data.tee_time.players &&
        this.data.tee_time.course
      ) {
        BookingController.loadRate(this.data).then((response: any) => {
          if (response === null) {
            this.rate = null;
          } else {
            this.rate = response.data;
          }
        });
        this.data.rental_clubs = [];
        RentalClubIndex.getData({
          filters: { course_id: this.data.tee_time.course.id },
        } as RentalClubIndexOptions).then((response) => {
          for (const club of response.data) {
            if (club === undefined || club === null) {
              continue;
            }
            this.data.rental_clubs.push(club);
          }
        });
      } else {
        this.rate = null;
      }
    },
    computeClubTotal(): number {
      let total = 0;
      for (const club of this.data.rental_clubs) {
        if (club.quantity > 0 && club.raw_charge > 0) {
          total += (club.raw_charge / 100) * club.quantity;
        }
      }
      return total;
    },
    bookTime() {
      if (!this.validateForm()) {
        alert('One or more validation errors are present.');
        return;
      }
      if (
        this.data.credit_card.number === '' &&
        !confirm('No credit card information entered. Continue to book?')
      ) {
        return;
      }
      // Let's take a screenshot of the form.
      window.scrollTo(0, 0);
      html2canvas(document.getElementsByTagName('form')[0]).then((canvas) => {
        this.data.screenshot = canvas.toDataURL('image/png');
        if (this.data.tee_time.confirmation === '') {
          this.data.tee_time.confirmation = 'INTERNAL';
        }
        this.processor.saveData(
          BookingController,
          this.data,
          this.$refs.submitForm,
          BookingController.book,
        );
      });
    },
    getPreselectedCourse(): string {
      if (!Number.isNaN(Number(this.$route.query.providedCourseId))) {
        return String(this.$route.query.providedCourseId);
      }
      return 'Torrey Pines - South';
    },
    updateSelectedDeal(selectedDealId: number) {
      this.data.deal_id = selectedDealId;
    },
    loadCardFromQueue(queueId: number) {
      QueueIbeUtil.getCard(queueId).then((response) => {
        this.data.credit_card = QueueIbeUtil.processCard(response);
      });
    },
  },
  computed: {
    computedDiscountErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.discount_code === undefined) {
        return this.discountErrors;
      }
      return this.discountErrors.concat(
        this.processor.errorHandler.ruleMap.discount_code,
      );
    },
    computedGiftCertErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.gift_cert_serial === undefined) {
        return this.giftCertErrors;
      }
      return this.giftCertErrors.concat(
        this.processor.errorHandler.ruleMap.gift_cert_serial,
      );
    },
    dollarOrPercent(): string {
      if (this.data.tee_time.discount === undefined) {
        return '';
      }
      return this.data.tee_time.discount.is_dollars ? '$' : '%';
    },
    discountAmount(): string {
      if (
        this.data.tee_time.discount === undefined ||
        this.data.charge === undefined
      ) {
        return '$0';
      }
      const amount = ComputeDiscountAmount(
        this.data.tee_time.discount,
        this.data.charge,
      );
      return `$${amount}`;
    },
    giftCertAmount(): string {
      if (!this.data.tee_time.gift_certificate) {
        return '$0';
      }
      return `$${Number(this.data.tee_time.gift_certificate.raw_balance) /
        100}`;
    },
    rentalClubTotal(): string {
      return `$${this.computeClubTotal().toFixed(2)}`;
    },
    total(): string {
      if (this.data.charge === undefined || this.data.charge === 0) {
        return '$0';
      }
      const charge = Number(this.data.charge);
      let total = charge + this.computeClubTotal();
      if (this.data.tee_time.discount) {
        total -= ComputeDiscountAmount(this.data.tee_time.discount, charge);
      }
      if (this.data.tee_time.gift_certificate) {
        const balance =
          Number(this.data.tee_time.gift_certificate.raw_balance) / 100;
        if (balance > total) {
          total = 0;
        } else {
          total -= balance;
        }
      }
      return `$${total.toFixed(2)}`;
    },
    playDateErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.tee_time === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.tee_time.play_date;
    },
    confirmationErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.tee_time === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.tee_time.confirmation;
    },
    playersErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.tee_time === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.tee_time.players;
    },
    courseErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.tee_time === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.tee_time.course;
    },
    playTimeErrors(): string[] {
      if (this.processor.errorHandler.ruleMap.tee_time === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.tee_time.play_time;
    },
    chargeErrors(): string[] {
      if (
        !this.data.charge ||
        this.data.charge <= 0 ||
        isNaN(Number(this.data.charge))
      ) {
        return ['You must charge more than $0.'];
      }
      if (this.processor.errorHandler.ruleMap.booking === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.booking.total;
    },
    cardRules(): string[] {
      if (this.processor.errorHandler.ruleMap.credit_card === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.credit_card;
    },
    courseRules(): string[] {
      if (
        this.processor.errorHandler.ruleMap.tee_time === undefined ||
        this.processor.errorHandler.ruleMap.tee_time.course === undefined
      ) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.tee_time.course;
    },
    customerRules(): any {
      if (this.processor.errorHandler.ruleMap.customer === undefined) {
        return [];
      }
      return this.processor.errorHandler.ruleMap.customer;
    },
    rateError(): string {
      const rateErrors = this.processor.errorHandler.ruleMap?.tee_time
        ?.golf_rate;
      if (Array.isArray(rateErrors) && rateErrors.length > 0) {
        return rateErrors[0];
      }
      return '';
    },
    customerEmail(): string {
      if (!this.data.tee_time || !this.data.tee_time.customer) {
        return '';
      }
      return this.data.tee_time.customer.email;
    },
  },
  watch: {
    data: {
      handler() {
        // Update special formatted fields.
        this.playDateFormatted = formatDate(this.data.tee_time.play_date);

        // Clear the rule maps to allow the form to re-validate.
        this.processor.errorHandler.buildRuleMap();
        this.processor.errorHandler.clearErrors();
      },
      deep: true,
    },
    playDateFormatted: {
      handler() {
        const e = this.$refs.playDate as HTMLElement;
        e.focus();
      },
    },
  },
});
