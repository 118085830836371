
import { Deal } from '../../api/pipedrive/deal';
import { DealIndex } from '../../api/pipedrive/index';

import Vue from 'vue';

export default Vue.component('deal-index', {
  props: ['customerEmail', 'dealId', 'shouldClear', 'shouldLoad'],
  data() {
    return {
      deals: [] as Deal[],
      loading: false,
      loadingText: 'Fetcing deals',
      headers: [
        { text: 'Select', value: 'select_slot' },
        { text: 'Title', value: 'title' },
        { text: 'View', value: 'view' },
      ],
      selectedDealId: 0,
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      DealIndex.getDeals(this.dealId, this.customerEmail).then((response) => {
        if (!response || !response.data || !response.data.deals) {
          return;
        }
        this.deals = DealIndex.parseDeals(response);
        this.loading = false;
      });
    },
  },
  watch: {
    shouldLoad(newShouldLoad, oldShouldLoad) {
      this.loadData();
    },
    shouldClear(newShouldClear, oldShouldClear) {
      this.deals = [];
    },
    selectedDealId(newSelectedDealId, oldSelectedDealId) {
      this.$emit('dealselected', newSelectedDealId);
    },
  },
});
