import { Course, DefaultCourse } from '../courses/course';
import { RateDateRange } from './rate_date_range';

export interface Rate {
  id: number;
  course: Course;
  // TODO Refactor days and dates to interfaces.
  days: string;
  dates: string;
  times: string;
  rate: string;
  rawRate?: number;
  deposit: string;
  wholesale_rate: string;
  max_refund: string;
  non_refundable: string;
  cancel_hours: number;
  course_balance: string;
  max_advance_days: number;
  commission: number;
  is_commission_dollars: boolean;
  disabled: boolean;
  internal_memo: string;
  external_memo: string;
  rate_date_ranges: RateDateRange[];
  archived: boolean;
  rate_sheet_note: string;
  type: string;
  humanized_type: string;
  golfswitch_commission: number;
  is_golfswitch_commission_dollars: boolean;
  is_dynamic: boolean;
  is_dynamic_greater: boolean;
}

const DEFAULT_RATE: Rate = {
  id: -1,
  course: DefaultCourse(),
  days: '',
  dates: '',
  times: '',
  rate: '',
  deposit: '',
  wholesale_rate: '',
  max_refund: '',
  non_refundable: '',
  cancel_hours: 0,
  course_balance: '',
  max_advance_days: 0,
  commission: 0,
  is_commission_dollars: true,
  disabled: false,
  internal_memo: '',
  external_memo: '',
  rate_date_ranges: [],
  archived: false,
  rate_sheet_note: '',
  type: '',
  humanized_type: '',
  golfswitch_commission: 0,
  is_golfswitch_commission_dollars: false,
  is_dynamic: false,
  is_dynamic_greater: false,
};

export function DefaultRate(): Rate {
  return JSON.parse(JSON.stringify(DEFAULT_RATE));
}
